import React, { useState } from 'react';
import { Button, Grid, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { googleImage } from '../../../blocks/social-media-account/src/assets';
import Loader from '../../../components/src/Loader';
import { fbBtnImage } from '../../../blocks/social-media-account-registration/src/assets';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import MobileNumber from './mobileNumber.web';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from "react-i18next";
import { useGoogleLogin } from '@react-oauth/google';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(0, 17),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    justifyContent: 'center',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(0, '27px'),
    },
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(0, 13),
    },
    [theme.breakpoints.only('md')]: {
      padding: theme.spacing(0, 7),
    },
    [theme.breakpoints.only('lg')]: {
      padding: theme.spacing(0, 12),
    },
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: 44,
    backgroundColor: '#38006D',
    color: '#ffff',
    fontFamily: `'Montserrat', sans-serif`,
    fontWeight: 600,
    padding: '15px 0',
    '&:hover': {
      background: '#38006D',
    },
  },
  fb_signupBtn: {
    backgroundColor: '#2D37B4',
    borderRadius: 44,
    color: '#ffff',
    border: 'none',
    width: '180px',
    height: '50px',
    '&:hover': {
      background: '#2D37B4',
      border: 'none',
    },
    [theme.breakpoints.down('md')]: {
      width: '160px',
      height: '40px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '125px',
      height: '38px',
    },
  },
  fbBtnImage: {
    width: '20px',
    [theme.breakpoints.down('md')]: {
      width: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '15px',
    },
  },
  google_signupBtn: {
    backgroundColor: '#E8E8EA',
    borderRadius: 44,
    color: 'black',
    border: 'none',
    width: '180px',
    height: '50px',
    '&:hover': {
      background: '#E8E8EA',
      border: 'none',
    },
    [theme.breakpoints.down('md')]: {
      width: '160px',
      height: '40px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '125px',
      height: '38px',
    },
  },
  googleBtnImage: {
    width: '35px',
    [theme.breakpoints.down('md')]: {
      width: '33px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '30',
    },
  },
  signupTitleText: {
    fontFamily: `'Montserrat', sans-serif`,
    fontWeight: 600,
    fontSize: '35px',
    lineHeight: '43px',
    color: '#37006E',
    paddingBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '25px',
      lineHeight: '33px',
    },
  },
  signupContentText: {
    fontFamily: `'Montserrat', sans-serif`,
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '30px',
    textAlign: 'center',
    color: '#968C8C',
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
      lineHeight: '18px',
    },
  },
  signup_with_fb_gl_text: {
    fontFamily: `'Montserrat', sans-serif`,
    fontWeight: 500,
    fontSize: '20px',
    textAlign: 'center',
    color: '#968C8C',
    margin: '2rem 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '1.2rem 0',
    },
  },
  tms_and_condi_text: {
    fontFamily: `'Montserrat', sans-serif`,
    fontWeight: 500,
    fontSize: '20px',
    textAlign: 'center',
    color: '#37474F',
    margin: '2rem 0',
    [theme.breakpoints.only('xs')]: {
      fontSize: '11px',
      margin: '1.4rem 0',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '16px',
      margin: '1.5rem 0',
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '14px',
      margin: '1.5rem 0',
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '18px',
      margin: '2rem 0',
    },
  },
  t_c_policy_text: {
    color: '#ec6b47',
  },

}));

interface Props {
  sentOtpRequest: (phoneNumber: string) => void;
  navigationBtnFnc: (path: any) => void;
  socialFacebookAuthResponse: (response: any) => void;
  socialGoogleAuthResponse: (response: any) => void;
  loading: boolean;
  gotErrorResponse: boolean;
}

const MobilePhoneInputPage = ({
  sentOtpRequest,
  socialFacebookAuthResponse,
  socialGoogleAuthResponse,
  navigationBtnFnc,
  loading,
  gotErrorResponse
}: Props) => {
  const classes = useStyles();
  const [t] = useTranslation(["translation", "signUpLoginLng"]);
  const [phoneNumValue, setPhoneNumValue] = useState('');
  const [validationError, setValidationError] = useState(false);

  const login = useGoogleLogin({
    onSuccess: (tokenResponse: any) => getUserInformation(tokenResponse),
  });

  const getUserInformation = async (response: any) => {
    try {
      // Fetch the user information using the access token
      const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: {
          Authorization: `Bearer ${response.access_token}`,
        },
      });
      const userInfo = await userInfoResponse.json();  
      socialGoogleAuthResponse(userInfo)
      
    } catch (error) {
      return error
      
    }
  }

  const handelPhoneNumberSignup = (e: any) => {
    e.preventDefault();
    if (phoneNumValue.length >= 9) {
      setValidationError(false);
      sentOtpRequest(phoneNumValue?.split('+')[1]);
      localStorage.setItem('inputPhoneNumber', phoneNumValue?.split('+')[1]);
    } else {
      setValidationError(true);
    }
  };
  if (loading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100vh' }}
      >
        <Loader loading={loading} />
      </Grid>
    );
  }

  return (
    <div className={classes.paper}>
      <Typography
        component="h1"
        variant="h5"
        className={classes.signupTitleText}
      >
        {t("signUpLoginLng:SignupOrLogin")}
      </Typography>

      <Typography component="h1" className={classes.signupContentText}>
        {t("signUpLoginLng:otpText")}
      </Typography>

      <form className={classes.form} onSubmit={handelPhoneNumberSignup}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {/* @ts-ignore */}
          <MobileNumber setPhoneNumValue={setPhoneNumValue} />
        </div>
        {validationError || gotErrorResponse ? (
          <Alert style={{
            fontFamily: `'Montserrat', sans-serif`,
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "12px",
            textAlign: "center",
            color: "#37474F"
          }}
            severity="error">
            {gotErrorResponse ? "Please check your number" : "Please Enter The Valid Mobile Number"}
          </Alert>) : null}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          className={classes.submit}
        >
          {t("signUpLoginLng:SendOTP")}
        </Button>
      </form>
      <div>
        <Typography className={classes.signup_with_fb_gl_text}>
          {t("signUpLoginLng:OrSignupWith")}
        </Typography>

        <div>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              {/* @ts-ignore */}
              <FacebookLogin
                appId="562385562194847"
                autoLoad={false}
                fields="name,email,picture"
                callback={socialFacebookAuthResponse}
                cssClass="my-facebook-button-class"
                icon="fa-facebook"
                render={(renderProps) => (
                  <Button
                    onClick={renderProps.onClick}
                    variant="contained"
                    className={classes.fb_signupBtn}
                  >
                    <img src={fbBtnImage} className={classes.fbBtnImage} />
                  </Button>
                )}
              />

            </Grid>
            <Grid item>
              {/* @ts-ignore */}
             
              <Button
                variant="outlined"
                color="primary"
                className={classes.google_signupBtn}
                onClick={() => login()}
              >
                <img src={googleImage} className={classes.googleBtnImage} />
              </Button>
            </Grid>
          </Grid>
        </div>

        <Typography className={classes.tms_and_condi_text}>
          {t("signUpLoginLng:T&CPrivacyPolicy1")}
          <span className={classes.t_c_policy_text}> <Link style={{ cursor: 'pointer', color: '#ec6b47' }} onClick={() => navigationBtnFnc('TermsAndConditionsWeb')}>{t("signUpLoginLng:T&CPrivacyPolicy2")}</Link> <Link style={{ cursor: 'pointer', color: '#ec6b47' }} onClick={() => navigationBtnFnc('ContentManagementPrivacyPolicy')} >{t("signUpLoginLng:T&CPrivacyPolicy3")}  </Link>
          </span>
        </Typography>
      </div>
    </div>
  );
};

export default MobilePhoneInputPage;
