// Customizable Area Start
import React from "react";
import { Typography, Grid, Card, CardMedia, CardContent, Avatar, FormControlLabel, FormControl, Radio, RadioGroup } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import SearchControllerWeb, { Props } from "./SearchControllerWeb";
import { searchBgImage, NoProductFoundImg } from "./assets";
import CircularProgress from '@material-ui/core/CircularProgress';
import PaginationWeb from "../../../components/src/Pagination.web";
import { fill, unfill } from "../../../blocks/advancedsearch/src/assets";
import { withTranslation } from 'react-i18next';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: 10,
      [theme.breakpoints.up(600)]: {
        marginTop: 64,
      },
      [theme.breakpoints.down(600)]: {
        marginTop: 48,
      },
      [theme.breakpoints.down(515)]: {
        marginTop: 56,
      },
    },

    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "baseline",
      height: "auto",
      margin: 50,
      [theme.breakpoints.down("lg")]: {
        margin: 40,
      },
      [theme.breakpoints.down("md")]: {
        margin: 25,
      },
      [theme.breakpoints.down("sm")]: {
        margin: 10,
      },
    },

    sideCompContainer: {
      height: "100%",
      width: 250,
      [theme.breakpoints.down("sm")]: {
        width: 200
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },

    filterContainer: {
      background: `linear-gradient(180.37deg, #38006F 0.32%, #B25843 232.68%)`,
      boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12)",
      borderRadius: "8px",
      marginBottom: 25,
      height: 200,
      width: "100%",
    },

    filterTitle: {
      fontFamily: `'Montserrat', sans-serif`,
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      letterSpacing: "0.03em",
      color: "#FFE601",
      padding: 20,
    },

    FoodTypeUnderline: {
      textUnderlineOffset: "0.5rem",
      textDecoration: "underline",
      textDecorationColor: "#FB951A",
    },

    filterNames: {
      fontFamily: `'Montserrat', sans-serif`,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "10px",
      lineHeight: "12px",
      color: "#FFFFFF",
      textTransform: "capitalize"
    },

    AdvertisementContainer: {
      height: "541px",
      background: `linear-gradient(180.37deg, #38006F 0.32%, #B25843 232.68%)`,
      boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12)",
      borderRadius: "8px",
      display: "block",
    },

    AdvertisementTitle: {
      fontFamily: `'Poppins', sans-serif`,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "34px",
      letterSpacing: "0.03em",
      color: "#FFFFFF",
      position: "relative",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },


    },

    rootProductsContainer: {
      width: "100%",
    },

    textContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: 25,
      marginRight: 25,
      [theme.breakpoints.down("sm")]: {
        alignItem: "baseline",
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: 5,
        marginRight: 5,
      },
    },

    searchResultText: {
      fontFamily: `'Poppins', sans-serif`,
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "22px",
      lineHeight: "33px",
      color: "#37006E",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        lineHeight: "12px",
      },

    },

    totalResultText: {
      fontFamily: `'Poppins', sans-serif`,
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "17px",
      lineHeight: "12px",
      color: "#38006D",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",

      },
    },

    productsContainer: {
      margin: 20,
      display: "flex",
      justifyContent: "center",
      maxHeight: 640,
      overflow: "hidden",
      overflowY: "scroll",
      '&::-webkit-scrollbar': {
        width: '5px',
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,0.00)',
      }
    },

    media: {
      height: 100,
      width: "auto",
      margin: "0px auto",

    },

    niqAvatarA: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      backgroundColor: "#00AB79",
    },

    productName: {
      fontFamily: `'Montserrat', sans-serif`,
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "15px",
      color: "#455A64",
      textAlign: "left",
      Maxwidth: 300,
      height: 50,
      webkitLineClamp: 2,
      webkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: "24px",



    },

    pagenation: {
      marginTop: "auto",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
      },


    },

    filterScroll: {
      marginRight: "2px",
      height: 89,
      overflowY: "scroll",
      '&::-webkit-scrollbar': {
        width: '5px',
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#DFDEDE',

      }
    },

    noProductContainer: {

      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: 'center',
      paddingTop: 50,
      paddingBottom: 50,

      [theme.breakpoints.down("lg")]: {
        paddingTop: 40,
        paddingBottom: 40,
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: 25,
        paddingBottom: 25,
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: 15,
        paddingBottom: 15,
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: 5,
        paddingBottom: 5,
      },
    },

    noProductImage: {
      width: 200,
      height: 250,
      [theme.breakpoints.down("md")]: {
        width: 150,
        height: 200,
      },
      [theme.breakpoints.down("xs")]: {
        width: 100,
        height: 150,
      },
    },

    noProductTitle: {
      fontFamily: `'Poppins', sans-serif`,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '25px',
      lineHeight: "117.5%",
      color: '#FF3939',
      textAlign: "center",
      padding: 50,
      [theme.breakpoints.down("lg")]: {
        padding: 35,
      },
      [theme.breakpoints.down("md")]: {
        padding: 25,
        fontSize: '20px',
      },
      [theme.breakpoints.down("sm")]: {
        padding: 15,
      },
      [theme.breakpoints.down("xs")]: {
        padding: 10,
        fontSize: '16px',
      },
    },

    // Filter responsive
    filterContainerRes: {
      display: "none",
      [theme.breakpoints.down("xs")]: {
        background: `linear-gradient(180.37deg, #38006F 0.32%, #B25843 232.68%)`,
        boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12)",
        borderRadius: "8px",
        height: 35,
        width: "100%",
        marginTop: 20,
        display: "flex",
        alignItems: "center",

      },
    },

    filterTitleRes: {
      fontFamily: `'Montserrat', sans-serif`,
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      letterSpacing: "0.03em",
      color: "#FFE601",
      padding: 20,
      width: "inherit",
      textAlign: "left"
    },

    FoodTypeUnderlineRes: {
      textUnderlineOffset: "0.5rem",
      textDecoration: "underline",
      textDecorationColor: "#FB951A",
    },

    filterNamesRes: {
      fontFamily: `'Montserrat', sans-serif`,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "10px",
      lineHeight: "12px",
      color: "#FFFFFF",
      width: "max-content",
    },

    filterScrollRes: {
      display: "flex",
      marginRight: "2px",
      width: "inherit",
      overflowX: "scroll",
      '&::-webkit-scrollbar': {
        height: '1px',
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#DFDEDE',

      }
    },

  });

class Search extends SearchControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, t } = this.props;        
    return (

      <>
        {/* Banner Image*/}
        <img src={searchBgImage} alt="bgImage" style={{ width: "100%" }} className={classes.root} />

        <div className={classes.container}>

          {/* filter & advertisement components */}
          <div className={classes.sideCompContainer} >

            {/* Food Type */}
            <div className={classes.filterContainer} >
              <Typography className={classes.filterTitle}>
                <span className={classes.FoodTypeUnderline}>{t("Food")} </span>{t("Type")}
              </Typography>
              
              <div className={classes.filterScroll}>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="Food Type"
                    defaultValue={this.state.defaultFoodType}
                    defaultChecked={true}
                  >
                    {this.state?.filterCategory?.map((filterType: any, index: any) => {
                      return (
                        <FormControlLabel key={index} style={{ marginLeft: "5px", display: "flex", alignItems: "center", }}
                          data-testid="foodType"
                          value={filterType?.attributes?.category_type}
                          onChange={(e: any) => { this.FoodType(filterType?.id) }}
                          control={
                            <Radio icon={<img src={unfill} alt="" width={12} height={12} />} size="small"
                              checkedIcon={<img src={fill} alt="" width={12} height={12} />} />}
                          label={<Typography className={classes.filterNames}>{filterType?.attributes?.category_type} ({filterType?.attributes?.category_type === "Packaged Foods" ? this.state.pagenationData?.pagination?.total_count : 0})</Typography>} />)
                    })}
                  </RadioGroup>
                </FormControl>

              </div>
            </div>

            <div className={classes.AdvertisementContainer}>
              <Typography
                component={"div"}
                align="center"
                variant="subtitle1"
                className={classes.AdvertisementTitle}
              >
                {t("Advertisement")}
              </Typography>
            </div>

          </div>


          <div className={classes.rootProductsContainer}>

            {/* Total Results Count */}
            <div className={classes.textContainer}>
              <Typography className={classes.searchResultText}>
                <span className={classes.FoodTypeUnderline}>{t("Search_Res")}</span>{t("ult")}
              </Typography>
              <Typography className={classes.totalResultText}
                style={{ display: `${this.state.paginationRemove === true ? "none" : "block"}` }}
              >
                {t("Total_result")}: {this.state.pagenationData?.pagination?.total_count || 0}
              </Typography>
            </div>

            {/* Filter Container  Responsive*/}
            <div className={classes.filterContainerRes} >
              <Typography className={classes.filterTitleRes}>
                <span className={classes.FoodTypeUnderlineRes}>{t("Food")} </span>{t("Type")}
              </Typography>

              <div className={classes.filterScrollRes} style={{ marginRight: 10 }}>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="Food Type"
                    defaultValue={this.state.defaultFoodType}
                    style={{ display: "flex", flexWrap: "nowrap", flexDirection: "row" }}
                  >
                    {this.state.filterCategory?.map((filterType: any, index: any) => {
                      return (
                        <FormControlLabel key={index} style={{ marginLeft: "5px", display: "flex", alignItems: "center", }}
                          value={filterType?.attributes?.category_type}
                          data-testid="foodTypeRes"
                          onChange={(e: any) => { this.FoodType(filterType?.id) }}
                          control={
                            <Radio icon={<img src={unfill} alt="" width={12} height={12} />} size="small"
                              checkedIcon={<img src={fill} alt="" width={12} height={12} />} />}
                          label={<Typography className={classes.filterNamesRes}>{filterType?.attributes?.category_type} ({filterType?.attributes?.category_type === "Packaged Foods" ? this.state.pagenationData?.pagination?.total_count : 0})</Typography>} />
                      )
                    })}
                  </RadioGroup>
                </FormControl>
              </div>

            </div>
            {/* Products and Pagination Container */}
            <div style={{ display: "flex", flexDirection: "column", alignContent: "space-between", height: 720 }}>

              {/* Products Container */}
              <div className={classes.productsContainer} >

                {this.state?.noProducts ? <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", }}>
                  <div className={classes.noProductContainer}>
                    <img src={NoProductFoundImg} alt="bgImage"
                      className={classes.noProductImage} />
                    <Typography className={classes.noProductTitle}>{t("No_product_found")}</Typography>
                  </div>
                </div> :
                  <>
                    {this.state?.loading ? <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: 400 }}>
                      <CircularProgress style={{ width: "50px", height: "50px" }} />
                    </div> :

                      <Grid container justifyContent={"space-evenly"} alignItems={"center"} >

                        {this.state?.filteredSearchResults?.data?.map((e: any, index: any) => {
                          return (
                            <Grid item style={{ padding: 10, width: 220 }} key={e?.id}>
                              <Card style={{
                                boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12)", borderRadius: 6, cursor: "pointer",
                              }}                            
                                data-testid="card"
                                onClick={() => {
                                  this.onProductSelection(e)

                                }}
                              >

                                <CardMedia component="img" className={classes.media} image={e?.attributes?.image}
                                  alt={e?.attributes?.product_name} />

                                <CardContent style={{ display: "flex", justifyContent: "space-around", }}>

                                  <Typography
                                    variant="subtitle1"
                                    component="p" id="hello"
                                    className={classes.productName}>
                                    {e?.attributes?.product_name}
                                  </Typography>

                                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>


                                    <Avatar
                                      aria-label="recipe"
                                      className={classes.niqAvatarA}
                                      style={{
                                        fontSize: 14, background:this.handleToolTipStyle(e.attributes.product_rating)
                                      }}
                                    >{e?.attributes?.product_rating}</Avatar>

                                    <Typography
                                      style={{
                                        fontFamily: `'Montserrat', sans-serif`,
                                        fontStyle: "normal",
                                        fontWeight: 500,
                                        fontSize: "9px",
                                        textAlign: "left",
                                        color: "#757D8A",
                                        marginTop: 5,
                                      }}>
                                      N<sup>IQ</sup>&nbsp;{t("Score")}
                                    </Typography>
                                  </div>
                                </CardContent>

                              </Card>
                            </Grid>
                          );

                        })}
                      </Grid>

                    } </>}
              </div>

              {/* Pagination */}

              <div className={classes.pagenation}
                data-testid="pagination"
                style={{ display: `${this.state.paginationRemove === true ? "none" : "flex"}` }}
              >
                <PaginationWeb pageCount={this.state.pagenationData?.pagination?.total_pages ? this.state.pagenationData?.pagination?.total_pages : 0} pageChange={this.handlePageClick} pageNumber={this.state.pageNumber} />
              </div>

            </div>

          </div>
        </div>

      </>
    );
  }
}
export default withStyles(styles, { withTheme: true })(withTranslation('searchLng')(Search));
// Customizable Area End
