// Customizable Area Start
import React from "react";
import { Typography, Button, Grid, Card, CardContent, CardMedia, Avatar, Tooltip, Fab, } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import FavouriteSmartSearchController, { Props } from "./FavouriteSmartSearchController";
import { smartSearchBannerImage, NoProductFoundImg } from "./assets";
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import AddIcon from '@material-ui/icons/Add';
import FiltersWeb from "../../../components/src/smartSearch/Filters.web";
import ChipsWeb from "../../../components/src/smartSearch/Chips.web";
import CustomLoaderWeb from "../../../components/src/CustomLoader.web";
import FavSearchStatusWeb from "../../../components/src/smartSearch/FavSearchStatus.web";
import PaginationWeb from "../../../components/src/Pagination.web"
import { withTranslation } from 'react-i18next';


const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: 10,
      [theme.breakpoints.up(600)]: {
        marginTop: 64,
      },
      [theme.breakpoints.down(600)]: {
        marginTop: 48,
      },
      [theme.breakpoints.down(515)]: {
        marginTop: 56,
      },
    },
    container: {
      margin: 50,
      display: "flex",
      justifyContent: "space-evenly",
      columnGap: 25,
      [theme.breakpoints.down("sm")]: {
        margin: 25
      },
      [theme.breakpoints.down(750)]: {
        margin: 15
      },
    },
    sideCompContainer: {
      height: "100%",
      minWidth: 270,
      maxWidth: 271,

      display: "flex",
      flexDirection: "column", justifyContent: "center", alignItems: "center",

      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    SmartSearchUnderline: {
      textUnderlineOffset: "0.5rem",
      textDecoration: "underline",
      textDecorationColor: "#FB951A",
    },
    FavouriteSearchButton: {
      fontFamily: `'Poppins', sans-serif`,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: "12px",
      textAlign: 'center',
      textTransform: 'capitalize',
      color: '#FFFFFF',
      background: '#38006D',
      borderRadius: "6px",
      padding: '10px',
      '&:hover': {
        background: '#12125c',
        border: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        padding: 5
      },

    },
    searchProductsContainer: {
      width: "80%",
      [theme.breakpoints.down('xs')]: {
        width: "100%",
      },
    },
    productsContainerButtons: {
      display: "flex", justifyContent: "space-between", alignItems: "center",
      width: "100%"
    },
    smartSearchResultText: {
      fontFamily: `'Poppins', sans-serif`,
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: '22px',
      lineHeight: '33px',
      color: '#37006E',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      },
    },
    media: {
      height: 100,
      width: 200,
      margin: "0px auto",

    },
    productName: {
      fontFamily: `'Montserrat', sans-serif`,
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "15px",
      color: "#455A64",
      textAlign: "left",
      Maxwidth: 300,
      height: 50,
      webkitLineClamp: 2,
      webkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: "24px",
    },
    niqAvatarA: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      backgroundColor: "#00AB79",
    },
    productsContainer: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      columnGap: '20px',
      minHeight: 532,
      overflow: "hidden",
      overflowY: "scroll",
      '&::-webkit-scrollbar': {
        width: '5px',
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,0.00)',
      }
    },
    noProductContainer: {

      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: 'center',
      paddingTop: 50,
      paddingBottom: 50,

      [theme.breakpoints.down("lg")]: {
        paddingTop: 40,
        paddingBottom: 40,
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: 25,
        paddingBottom: 25,
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: 15,
        paddingBottom: 15,
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: 5,
        paddingBottom: 5,
      },
    },
    noProductImage: {
      width: 200,
      height: 250,
      [theme.breakpoints.down("md")]: {
        width: 150,
        height: 200,
      },
      [theme.breakpoints.down("xs")]: {
        width: 100,
        height: 150,
      },
    },
    noProductTitle: {
      fontFamily: `'Poppins', sans-serif`,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: "117.5%",
      color: '#FF3939',
      textAlign: "center",
      padding: 50,
      [theme.breakpoints.down("lg")]: {
        padding: 35,
      },
      [theme.breakpoints.down("md")]: {
        padding: 25,
      },
      [theme.breakpoints.down("sm")]: {
        padding: 15,
      },
      [theme.breakpoints.down("xs")]: {
        padding: 10,
      },
    },
    handleFilterRespStyle: {
      display: "none",
      [theme.breakpoints.down("xs")]: {
        position: "sticky",
        bottom: "10px",
        right: "5px",
        display: "flex", justifyContent: "flex-end"
      },
    },
    sideCompContainerResp: {
      display: "none",
      [theme.breakpoints.down("xs")]: {
        height: 450,
        maxWidth: 270,
        display: "flex",
        background: `linear-gradient(180.37deg, #38006F 0.32%, #B25843 232.68%)`,
        boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12)",
        flexDirection: "column", justifyContent: "center", alignItems: "center",
      },
    },
    popper: {
      background: "#12125c"
    },
    arrow: {
      color: "#12125c"
    },
    pagenation: {
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
  });

class SmartSearch extends FavouriteSmartSearchController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, t } = this.props;
    return (
      <div className={classes.root}>
        <img src={smartSearchBannerImage} alt="bgImage" style={{ width: "100%" }} />
        <div className={classes.container}>
          <div className={classes.sideCompContainer}>

            <FiltersWeb
              foodTypes={this.state.foodTypes}
              category={this.state.category}
              subCategory={this.state.subCategory}
              niqScore={this.state.niqScore}
              foodAllergies={this.state.foodAllergies}
              foodPreferences={this.state.foodPreferences}
              functionalPreferences={this.state.functionalPreferences}
              healthPreferences={this.state.healthPreferences}
              smartSearchFilters={this.state.smartSearchFilters}
              chipTypeLabel={this.state.chipTypeLabel}
              handleCategoryTypeChange={this.handleCategoryTypeChange}
              handleFoodTypeChange={this.handleFoodTypeChange}
              handleSubCategoryTypeChange={this.handleSubCategoryTypeChange}
              handleNiqScoreTypeChange={this.handleNiqScoreTypeChange}
              handleFoodAllergiesTypeChange={this.handleFoodAllergiesTypeChange}
              handleFoodPreferencesTypeChange={this.handleFoodPreferencesTypeChange}
              handleFunctionalPreference={this.handleFunctionalPreference}
              handleHealthPreferenceTypeChange={this.handleHealthPreferenceTypeChange}
              debounceLog={this.debounceLog}
              onHandleSearchClick={this.onHandleSearchClick}
              onHandleAddToFavSearch={this.onHandleAddToFavSearch}
              clearAllFilters={this.clearAllFilters}
              filterLoaders={this.state.filterLoaders}
            />
          </div>

          <div className={classes.searchProductsContainer}>
            <div className={classes.productsContainerButtons}>
              <Typography className={classes.smartSearchResultText}><span className={classes.SmartSearchUnderline}>{t("SmartSearchResult1")}</span>{t("SmartSearchResult2")} {this.state.smartSearchPagination?.pagination?.total_count > 0 && !this.state.isFilterSelect ? `(${this.state.smartSearchPagination?.pagination?.total_count})` : ''}</Typography>
              <Button className={classes.FavouriteSearchButton} onClick={() => this.navigationBtnFnc("FavouriteSmartSearchWeb")} data-testid='favSearchBtn'>{t("FavouriteSearches")}</Button>
            </div>

            <ChipsWeb
              handleFavChips={this.state.handleFavChips}
              smartSearchFilters={this.state.smartSearchFilters}
              displayFavChips={this.state.displayFavChips}
            />

            {this.state.isFilterSelect ?
              <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", }}>
                <div className={classes.noProductContainer}>
                  <img src={NoProductFoundImg} alt="bgImage" className={classes.noProductImage} />
                  <Typography className={classes.noProductTitle}>{t("selectFilter")}</Typography>
                </div>
              </div> :
              <>
                {this.state?.loading ? <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: 400 }}>
                  <CircularProgress style={{ width: "50px", height: "50px" }} />
                </div> :
                  <Grid container className={classes.productsContainer}>

                    {this.state.smartSearchResultsDisplay?.data?.map((results: any, index: any) => {
                      return (
                        <Grid item style={{ padding: '10px 1px', margin: "5px 0", width: 200, height: 200 }} key={index}>
                          <Card style={{ boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12)", borderRadius: 6, cursor: "pointer", }}
                            onClick={() => this.getProductsDetails(results)}
                            data-testid="getProductDetails"
                          >

                            <CardMedia component="img" className={classes.media} image={results?.attributes?.image} alt={results?.attributes?.product_name} />
                            <CardContent style={{ display: "flex", justifyContent: "space-around", }}>

                              <Typography
                                variant="subtitle1"
                                component="p"
                                className={classes.productName}>{results?.attributes?.product_name}</Typography>

                              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Avatar
                                  aria-label="recipe"
                                  className={classes.niqAvatarA}
                                  style={{
                                    fontSize: 14,
                                    background: this.handleProductRating(results?.attributes?.product_rating)
                                  }}>{results?.attributes?.product_rating}</Avatar>

                                <Typography
                                  style={{
                                    fontFamily: `'Montserrat', sans-serif`, fontStyle: "normal", fontWeight: 500,
                                    fontSize: "9px", textAlign: "left", color: "#757D8A", marginTop: 5,
                                  }}>N<sup>IQ</sup>&nbsp;Score</Typography>
                              </div>
                            </CardContent>

                          </Card>
                        </Grid>
                      );

                    })}
                  </Grid>
                }
              </>
            }

            <div className={classes.pagenation} style={{ display: this.state.isFilterSelect || this.state.loading ? "none" : "flex", }} >
              <PaginationWeb pageCount={this.state.smartSearchPagination?.pagination?.total_pages ? this.state.smartSearchPagination?.pagination?.total_pages : 0} pageChange={this.handlePageClick}
                pageNumber={this.state.pageNumber}
              />

            </div>

            <div className={classes.handleFilterRespStyle}>
              <Tooltip title={!this.state.handleFilterInResp ? "Add Filters" : ""}
                open={!this.state.smartsearchLoading} arrow placement="left"
                classes={{ tooltip: classes.popper, arrow: classes.arrow, }}>
                <Fab size="small" onClick={() => this.setState({ handleFilterInResp: true })} style={{ background: "#fff" }} data-testid='filterButtonResp' >
                  <AddIcon style={{ color: "#FF3939" }} />
                </Fab>
              </Tooltip>
            </div>

            <CustomLoaderWeb favSearchLoader={this.state.favSearchLoader} />
            <CustomLoaderWeb favSearchLoader={this.state.smartsearchLoading} />
            <FavSearchStatusWeb open={this.state.isAgainAddToFav} content={"Already added to your favourite search"} color={"#FFB946"} />
            <FavSearchStatusWeb open={this.state.AddFavouriteSearch} content={"Products added To favourites successfully"} color={"#0EAA58"} />
            <FavSearchStatusWeb open={this.state.nofilterSelectedError} content={"Please select atleast one filter"} color={"#FFB946"} />

            {/* @ts-ignore */}
            <Modal
              open={this.state.handleFilterInResp}
              data-testid="filterModalClose"
              onClose={() => { this.setState({ handleFilterInResp: false }) }}
              style={{
                display: "flex", justifyContent: "center", alignItems: "center",
                background: 'linear-gradient(167.38deg, rgba(10, 0, 48, 0.65) 3.11%, rgba(38, 2, 37, 0.65) 91.84%',
              }} >
              <div className={classes.sideCompContainerResp}>

                <FiltersWeb
                  foodTypes={this.state.foodTypes}
                  category={this.state.category}
                  subCategory={this.state.subCategory}
                  niqScore={this.state.niqScore}
                  foodAllergies={this.state.foodAllergies}
                  foodPreferences={this.state.foodPreferences}
                  functionalPreferences={this.state.functionalPreferences}
                  healthPreferences={this.state.healthPreferences}
                  smartSearchFilters={this.state.smartSearchFilters}
                  chipTypeLabel={this.state.chipTypeLabel}
                  handleFoodTypeChange={this.handleFoodTypeChange}
                  handleCategoryTypeChange={this.handleCategoryTypeChange}
                  handleSubCategoryTypeChange={this.handleSubCategoryTypeChange}
                  handleNiqScoreTypeChange={this.handleNiqScoreTypeChange}
                  handleFoodAllergiesTypeChange={this.handleFoodAllergiesTypeChange}
                  handleFoodPreferencesTypeChange={this.handleFoodPreferencesTypeChange}
                  handleFunctionalPreference={this.handleFunctionalPreference}
                  handleHealthPreferenceTypeChange={this.handleHealthPreferenceTypeChange}
                  debounceLog={this.debounceLog}
                  onHandleSearchClick={this.onHandleSearchClick}
                  onHandleAddToFavSearch={this.onHandleAddToFavSearch}
                  clearAllFilters={this.clearAllFilters}
                />
              </div>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(withTranslation('smartSearchLng')(SmartSearch));
// Customizable Area End
